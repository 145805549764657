import {IczFormGroup, IczValidatorFn, IczValidators, ValidationErrorMessage} from '@icz/angular-form-elements';
import {AbstractControl} from '@angular/forms';
import {format} from 'date-fns';
import {DestroyRef} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export class StorageUnitValidators {

  @ValidationErrorMessage('Rok vyřazení musí být v budoucnosti.')
  static futureDisposalYear(): IczValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;

      if (value) {
        const currentYear = format(new Date(), 'yyyy');
        return value <= currentYear ? {futureDisposalYear: true} : null;
      }
      else {
        return null;
      }
    };
  }

}

export const STORAGE_UNIT_DISPOSAL_YEAR_VALIDATORS = [IczValidators.isStringifiedInteger(), StorageUnitValidators.futureDisposalYear()];

export function initStrictEnforcementsValidations(form: IczFormGroup, destroyRef: DestroyRef) {
  const enforceStrictEntityClassControl = form.get('enforceStrictEntityClass')!;
  const enforceStrictDisposalYearControl = form.get('enforceStrictDisposalYear')!;

  enforceStrictEntityClassControl.valueChanges.pipe(
    takeUntilDestroyed(destroyRef),
  ).subscribe(value => {
    const entityClassControl = form.get('entityClassId')!;

    if (value) {
      entityClassControl.setValidators([IczValidators.required()]);
    }
    else {
      entityClassControl.setValidators([]);
    }

    entityClassControl.updateValueAndValidity();
  });

  enforceStrictDisposalYearControl.valueChanges.pipe(
    takeUntilDestroyed(destroyRef),
  ).subscribe(value => {
    const disposalYearControl = form.get('disposalYear')!;

    if (value) {
      disposalYearControl.setValidators([IczValidators.required(), ...STORAGE_UNIT_DISPOSAL_YEAR_VALIDATORS]);
    }
    else {
      disposalYearControl.setValidators(STORAGE_UNIT_DISPOSAL_YEAR_VALIDATORS);
    }

    disposalYearControl.updateValueAndValidity();
  });
}
