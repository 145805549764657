
@if (showDocumentSelection) {
  <icz-documents-table
    tableId="storage-unit-insert"
    class="grow"
    [dataSource]="dataSource"
    [viewType]="DocumentView.STORAGE_UNIT_INSERT"
    [openQuickPreview]="false"
    [hideTableToolbarButtons]="true"
    (selectedRowsChanged)="selectionChanged($event)"
  ></icz-documents-table>
}
@else {
  <form [formGroup]="form" [waiting]="loadingService.isLoading(this)" class="mx-16 my-16">
    <icz-section label="Volba ukládací jednotky">
      <div class="grow col">
        <div class="row mb-8 icz-body-1">
          <span>{{(modalData.isMove ? 'Přesouvané objekty' : 'Vkládané objekty' | translate) + ':'}}</span>
          @if (getDocumentsCount(); as documentCount) {
            <div>
              <span class="mr-2">{{'Dokumenty' | translate}}</span>
              <span>{{'(' + documentCount + ')'}}</span>
            </div>
          }
          @if (getFilesCount(); as fileCount) {
            <div>
              <span class="mr-2">{{'Spisy' | translate}}</span>
              <span>{{'(' + fileCount + ')'}}</span>
            </div>
          }
        </div>
        <icz-form-field
          label="Vyhledejte ukládací jednotku"
          formControlName="selectedStorageUnit"
          (click)="openStorageUnitSelection()"
          (keydown.arrowDown)="openStorageUnitSelection()"
          (keydown.enter)="openStorageUnitSelection()"
          (keydown.space)="openStorageUnitSelection()"
          [hideInputElement]="true"
          >
          <div class="internal-content">
            @if (placeholder) {
              <span class="icz-select-placeholder">{{ placeholder }}</span>
            }
            <ng-container>
              {{ viewValue }}
            </ng-container>
          </div>
          <icz-icon [svgIcon]="getObjectIcon()" prefix size="small"></icz-icon>
          <ng-container suffix>
            @if (isStorageUnitSelected()) {
              <icz-icon
                class="clear-selection"
                size="small"
                svgIcon="delete"
                (click)="clearClicked($event)">
              </icz-icon>
            }
            <icz-icon class="open-table"
              size="small"
              svgIcon="reference_list">
            </icz-icon>
          </ng-container>
        </icz-form-field>
      </div>
    </icz-section>
  </form>
}

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this) && isSubmitDisabled()"
    (onAction)="submit()"
    primary [label]="modalData.isMove ? 'Přesunout do ukládací jednotky' : 'Vložit do ukládací jednotky'"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
